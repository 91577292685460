<template>
<div class="cooperation">
  <img src="@/static/images/joinin_bg.png" class="mb-16 w-full" alt="">
  <div class="container mx-auto pb-32">
    <div class="row">
      <div class="col-lg-3 col-md-6 " v-for="item in companyConfig" :key="item.label">
        <div class="card bg-white pt-10 pb-12 px-8 flex flex-col items-center mb-6 lg:mb-0 transition-all duration-700 cursor-pointer transform hover:scale-105">
          <img :src="item.icon" alt="" :style="{paddingBottom:item.class}">
          <p class="text-xl text-center font-medium mb-7">{{item.label}}</p>
          <p class="text-lg lg:h-20">{{item.detail}}</p>
        </div>
      </div>
    </div>
  </div>
  <ConcatUs bg="#f4f4f4" />
</div>
</template>

<script>
import ConcatUs from "@/components/Home/ConcatUs/ConcatUs.vue"
import {
  mapState
} from "vuex"
export default {
  components: {
    ConcatUs
  },
  computed: {
    ...mapState({
      companyConfig: state => state.companyConfig
    })
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.cooperation {
  background: #f4f4f4;
}
</style>
