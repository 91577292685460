<template>
<div class="container mx-auto pt-24 md:py-80">
  <h1 class="text-6xl text-black font-extrabold mb-6" style="font-weight:'heavy'">PAGE NOT FOUND</h1>
  <h1 class="text-6xl text-black font-normal mb-16">页面没有找到！</h1>
  <div class="error_bg w-full h-96 relative mb-32 md:mb-52">
    <div class="md:absolute w-full left-0 top-8">
      <p class="text-3xl font-medium text-black mb-1">在此服务器上找不到请求URL… …</p>
      <p class="text-3xl font-medium text-black max-w-xl">The request URL was not found on this server.</p>
    </div>
    <div class="flex items-center justify-end mt-10 md:mt-0">
      <img src="@/static/images/404.png" alt="" style="width:45.25rem;">
    </div>
  </div>
  <div class="flex items-center justify-center mb-60">
    <button class="bg-orange py-6 px-24 text-xl text-white font-medium" @click="handleGoHome">返回首页</button>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    handleGoHome(){
      this.$router.push('/')
    }
  },
}
</script>