<template>
  <div>
    <div>
      <img :src="detail.backgroundImage"
           alt=""
           class="mb-24 w-full">
      <div class="container mb-32">
        <div class="ql-container">
          <div class="ql-editor"
               data-gramm="false"
               data-placeholder="Insert text here ..."
               v-html="detail.detail">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSmartDetail
} from "@/api"
export default {
  data () {
    return {
      id: this.$route.params.id,
      detail: {},
      loading: true
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.params.id)
      {
        this.id = this.$route.params.id
        this.fetchSmartDetail(this.$route.params.id)
      }
    }
  },
  created () {
    this.fetchSmartDetail(this.id)
  },
  methods: {
    async fetchSmartDetail (id) {
      const res = await getSmartDetail(id)
      this.detail = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
