<template>
<div class="bg-f4">
  <img src="@/static/images/support.png" class="w-full" alt="">
  <div class="container py-24 grid-cols-1 grid md:grid-cols-2 gap-4 overflow-hidden">
    <div class="text-center flex flex-col justify-center items-center text-white cursor-pointer transition-all duration-300 transform overflow-hidden support-items" style="height:36.5625rem" :style="[item.bg?{backgroundImage:`url(${item.bg})`,}:{background:'#000'}]" v-for="(item,index) in data" :key="index">
      <img :src="item.icon" :style="{paddingBottom:`${item.class}`}" alt="">
      <p class="text-3xl mb-7 font-medium">{{item.label}}</p>
      <p class="text-2xl font-light">{{item.cate}}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      data: [{
          icon: "http://image.syang.wang/2021-10-27/0de76262-c893-4c0c-b769-9e28d9bf73b8.png",
          label: "商业咨询",
          cate: "商业/金融",
          bg: "http://image.syang.wang/2021-10-27/8924d3b2-1f17-4be9-9281-b369b8e633af.png",
          class: "52px"
        },
        {
          icon: "http://image.syang.wang/2021-10-27/c68f29d5-5c64-4d07-9268-e8201eed7800.png",
          label: "数字营销",
          cate: "商业/金融",
          bg: "http://image.syang.wang/2021-10-27/2fa2ede9-f9c2-4291-8e42-f253b93a0e8d.png",
          class: "63px"
        },
        {
          icon: "http://image.syang.wang/2021-10-27/b10ab7e9-c9be-4db8-8b95-8a7d3274f598.png",
          label: "小程序开发",
          cate: "商业/软件",
          bg: "http://image.syang.wang/2021-10-27/b6d4802e-9350-4305-8faa-9073a61cef7d.png",
          class: "59px"
        },
        {
          icon: "http://image.syang.wang/2021-10-27/0d2be9d5-d253-421a-aaec-82d4e6fee0b2.png",
          label: "业务规划",
          cate: "商业/金融",
          bg: "",
          class: "55px"
        },
        {
          icon: "http://image.syang.wang/2021-10-27/f67c0e52-522b-40a3-8d4f-9673298bc834.png",
          label: "社交电商",
          cate: "社交/金融",
          bg: "",
          class: "68px"
        },
        {
          icon: "http://image.syang.wang/2021-10-27/c903ed73-2294-4317-9b4f-32d74baa647e.png",
          label: "智慧酒店",
          cate: "酒店",
          bg: "http://image.syang.wang/2021-10-27/93d5c35d-68bd-4ae1-8bda-cc91cd1ccb4f.png",
          class: "52px"
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.support-items {
  background-size: 110% 110% !important;
  background-repeat: no-repeat;
  background-position: center;
}

.support-items:hover {
  background-size: 100% 100% !important;
}
</style>
