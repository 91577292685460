<template>
<div class="detail_pages">
  <img src="@/static/images/news.png" alt="">
  <div class="bg">
    <div class="ye-ball bg-orange cursor-pointer text-xl" @click="handleGoBack()">
      返回
    </div>
  </div>
  <div class="py-40 container mx-auto">
    <div class="mb-10 bg-white py-10 px-8">
      <!-- <div class="md:flex md:items-center mb-6">
        <span class="border-tags py-2 px-8 bg-orange mr-6 text-white text-lg md:text-2xl ">官方订阅</span>
        <span class="text-lg text-999 mr-10">{{detail.createTime}}</span>
        <span class="flex items-center">
          <span class="text-lg text-999 mr-10 flex items-center mt-4 md:mt-0">
            <a-icon type="eye"></a-icon><span class="ml-2">199</span>
          </span>
          <span class="text-999 text-lg mt-4 md:mt-0 block">发布人:<span class="text-black">{{detail.createUserName}}</span></span>
        </span>
      </div> -->
      <img :src="detail.avatar" class="mb-8 w-full" alt="">
      <p class="text-xl text-333">{{detail.remark}}</p>
    </div>
    <div class="mb-10 bg-white py-10 relative overflow-hidden">
      <div class="absolute -left-12 transform h-12 bg-c0 top-3 text-center flex items-center justify-content-center -rotate-45 text-white text-xl px-16">
        正文
      </div>
      <div class="text-center text-3xl text-black font-medium mb-16 pl-12 lg:pl-8 pr-8">{{detail.title}}</div>
      <div class="ql-container px-4">
        <div class="ql-editor" data-gramm="false" data-placeholder="Insert text here ..." v-html="detail.content">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  getNewsDetail
} from "@/api"
export default {
  data() {
    return {
      id: this.$route.params.id,
      detail: {}
    }
  },
  created() {
    this.fetchDetail(this.id)
  },
  methods: {
    handleGoBack() {
      this.$router.go(-1)
    },
    async fetchDetail(id) {
      const res = await getNewsDetail(id)
      this.detail = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.detail_pages {
  overflow-x: hidden;
  background: #f4f4f4;
  img{
    width: 100%;
  }
}

.ye-ball {
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 10.75rem;
  line-height: 10.75rem;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
  margin-top: 24px;
  z-index: 2;
  box-shadow: 0.4375rem 0.4375rem 1.1875rem rgba(245, 188, 17, 0.47);
}

.bg {
  width: 16.75rem;
  position: absolute;
  left: 50%;
  height: 7rem;
  margin-top: -7rem;
  transform: translateX(-50%);
  background-image: url("../static/images/radius_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.border-tags {
  border-radius: 30px 0px 30px 30px;
}
::v-deep img{
  display: inline;
}
</style>
