<template>
<div class="detail_pages">
  <img src="@/static/images/product_detail_bg.png" alt="">
  <div class="bg">
    <div class="ye-ball bg-orange cursor-pointer text-xl" @click="handleGoBack()">
      返回
    </div>
  </div>
  <div class="container mx-auto" style="padding-top:13.5rem">
    <div class="grid grid-cols-1 lg:grid-cols-2 md:gap-x-10 gap-y-8 mb-20">
      <!-- 轮播 -->
      <div class="">
        <div class="w-full topSwiper" style="height:34.3125rem">
          <!-- <img class="w-full h-full object-cover"
                 :src="imgUrl"
                 alt=""> -->
          <swiper ref="topSwiper" :options="swiperTopOptions">
            <swiper-slide v-for="(c,i) in detail.cover" :key="i">
              <img class="w-full h-full object-cover" :src="c" alt="">
            </swiper-slide>
          </swiper>
        </div>
        <div class="mt-7">
          <div class="recommendPage">
            <swiper ref="mySwiper" :options="swiperOption">
              <swiper-slide v-for="(item,index) in detail.cover" :data-index="index" :key="item">
                <img style="width:9.375rem;height:auto" class="object-cover" :data-index="index" :src="item" alt="">
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="px-8 py-7 bg-white relative">
        <h1 class="text-center mb-16 text-4xl">{{detail.name}}</h1>
        <h1 class="text-center font-extrabold mb-8" style="color:#B70000;font-size:4.375rem;line-height: 6.125rem;"><span class="text-4xl">￥</span>{{detail.price}}</h1>
        <p class="text-black text-xl mb-16">
          {{detail.remark}}
        </p>
        <p class="text-orange mb-32" v-if="detail.productParameterList">
          <ul>
            <li v-for="c in detail.productParameterList" :key="c.id"><span class="mr-4">{{c.name | filterLabel}}</span>{{c.parameterValue}}</li>
          </ul>
        </p>
        <div class="flex items-center justify-center mb-6">
          <img src="@/static/images/mini_qrcode.png" alt="">
        </div>
        <div class="service text-999 text-center absolute bottom-4">小程序扫码查看更多!</div>
      </div>
    </div>
    <!-- 产品展示 -->
    <div class="content bg-white mb-52 py-8 px-10">
      <div class="service1 text-black text-center bottom-4 text-4xl font-medium mb-15">详情展示</div>
      <div class="ql-container">
        <div class="ql-editor" data-gramm="false" data-placeholder="Insert text here ..." v-html="detail.detail">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import {
  getProductDetail
} from "@/api"
export default {
  components: {
    swiper,
    swiperSlide
  },
  filters: {
    filterLabel(val) {
      if (val.indexOf(':' || "：") == -1) return val + ":"
      return val
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      currentIndex: 0,
      imgUrl: "",
      detail: {
        cover: []
      },
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 15,
        speed: 700,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          click: (e) => {
            this.ChangeSwiper(e.target)
          }
        }
      },
      swiperTopOptions: {
        loop: true,
        spaceBetween: 10,
        speed: 700,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        on: {
          slideChange: () => {
            this.mySwiper.slideTo(this.topSwiper.activeIndex, 700, false)
          }
        }
      }
    }
  },
  created() {
    this.fetchDetail(this.id)
  },
  computed: {
    topSwiper() {
      return this.$refs.topSwiper.swiper;
    },
    mySwiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    handleGoBack() {
      this.$router.go(-1)
    },
    async fetchDetail(id) {
      const res = await getProductDetail(id)
      this.detail = res.data
      if (this.detail.backgroundImage) {
        this.detail.cover = this.detail.backgroundImage.split(',')
        this.imgUrl = this.detail.cover[0]
      }
    },
    ChangeSwiper(el) {
      let index = Number(el.dataset.index)
      this.topSwiper.slideTo(index, 700, false)
    }
  }

}
</script>

<style lang="scss" scoped>
.detail_pages {
  overflow-x: hidden;
  background: #f4f4f4;
  img{
    width: 100%;
  }
}

.ye-ball {
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 10.75rem;
  line-height: 10.75rem;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
  margin-top: 24px;
  z-index: 2;
  box-shadow: 0.4375rem 0.4375rem 1.1875rem rgba(245, 188, 17, 0.47);
}

.bg {
  width: 16.75rem;
  position: absolute;
  left: 50%;
  height: 7rem;
  margin-top: -7rem;
  transform: translateX(-50%);
  background-image: url("../static/images/radius_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.service {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 3rem);

  &::after {
    content: "";
    width: 6rem;
    height: 1px;
    background: rgba(112, 112, 112, 0.15);
    margin-left: 12px;
  }

  &::before {
    content: "";
    width: 6rem;
    height: 1px;
    background: rgba(112, 112, 112, 0.15);
    margin-right: 12px;
  }
}

.service1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.0625rem;

  &::after {
    content: "";
    width: 6rem;
    height: 1px;
    background: #000;
    margin-left: 12px;
  }

  &::before {
    content: "";
    width: 6rem;
    height: 1px;
    background: #000;
    margin-right: 12px;
  }
}

.topSwiper .swiper-container {
  height: 34.3125rem;
}

.recommendPage {
  position: relative;
}

.recommendPage .swiper-container {
  position: relative;
  width: 80%;
}

.swiper-button-prev {
  background-image: url("../static/images/swiper_left.png");
  background-size: 3.5rem 3.5rem;
}

.swiper-button-next {
  background-image: url("../static/images/swiper_right.png");
  background-size: 3.5rem 3.5rem;
}
</style>
