<template>
<div class="bg-f4">
  <div class="container mx-auto ">
    <div class="search-box pt-40 pb-24 flex w-full items-center">
      <input type="text" v-model="query.name" class="bg-white outline-none text-3xl w-3/4 md:w-5/6" style="padding:30px;min-height:5.875rem" placeholder="智能网关" @keyup.enter="handleSearch">
      <span class="cursor-pointer bg-orange  inline-block text-3xl text-white text-center w-1/4 md:w-1/6" @click="handleSearch" style="min-height:5.875rem;padding:30px 0">搜索</span>
    </div>
    <div class="content pb-24">
      <div class="row">
        <!-- 分类列表 -->
        <div class="col-lg-3">
          <div class="bg-white mb-10 lg:mb-0" style="padding:3.3125rem 3.375rem 3.3125rem 3.0625rem">
            <ul>
              <li class="md:text-xl text-xl lg:text-2xl xl:text-3xl mb-8 md:mb-12 lg:mb-16 font-medium text-black cursor-pointer hover:text-orange transition-all duration-300" :class="currentIndex == index ? 'text-orange':''" @click="handleChangeCategory(item.id)" v-for="(item,index) in categoryList" :key="item.id">{{item.name}}</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="row lg:mb-0">
            <div class="col-12 flex items-center justify-center mb-12 lg:mb-24">
              <img src="@/static/images/product_bg.png" alt="">
            </div>
          </div>
          <!-- 产品列表 -->
          <div class="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <div class=" px-3 py-4 cursor-pointer bg-white animation-fade" :style="[{animationDelay: (index + 1) * 0.05 + 's'}]" v-for="(item, index) in productList" @click="handleViewDetail(item.id)" :key="item.id">
              <div class="bg-item w-full z-2 relative h-96 lg:h-96 xl:h-60 sm:h-96" :style="`background-image:url(${item.cover});background-size:cover;min-height:15rem`">
                <img src="@/static/images/product_item.png" alt="" class="absolute z-0 w-full h-full">
                <p class="absolute left-1.5 text-white bottom-0 DINMedium text-5xl leading-relaxed xl:text-3xl xl:mb-1 lg:text-4xl lg:leading-loose"><span class="text-lg">¥</span>{{item.price}}<span class="text-xs">元</span>
                  <span class="text-price text-2xl opacity-70 line-through ml-4 xl:text-xs xl:ml-2 xl:leading-relaxed lg:text-xl">{{item.retailPrice}}元</span></p>
                <p class="text-white absolute bottom-2 md:bottom-1 lg:bottom-1 right-10 md:right-2" v-if="item.pmsLabelVOList.length!=0" style="font-size:14px">{{item.pmsLabelVOList[0].name}}</p>
              </div>
              <p class="text-sm mb-4 mt-3 cursor-pointer text-black text-cut h-10">{{item.name}}</p>
              <div class="inline-block px-8 py-2 border-black hover:bg-black hover:text-white transition-all text-black" style="border-width:1px">查看详情</div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="flex w-full items-center lg:justify-center mt-24 flex-wrap text-2xl md:text-lg" v-if="pageNum">
            <div class="pre cursor-pointer mr-10 mb-4 md:mb-0" @click="handleChangePage(1)">首页</div>
            <div>
              <ul class="flex items-center flex-wrap w-full">
                <li class="px-4 py-3 cursor-pointer flex items-center justify-center" @click="handleChangePage(current - 1)">
                  <a-icon type="left" />
                </li>
                <li v-for="item in pageNum" class="px-4 py-3 cursor-pointer flex-shrink-0" @click="handleChangePage(item)" :class="item == current ?'bg-orange text-white':''" :key="item">{{item}}</li>
                <li class="px-4 py-3 cursor-pointer flex items-center justify-center" @click="handleChangePage(current + 1)">
                  <a-icon type="right" />
                </li>
              </ul>
            </div>
            <div class="next cursor-pointer mt-4 md:mt-0 ml-0 md:ml-10" @click="handleChangePage(pageNum)">尾页</div>
          </div>
          <div v-if="!pageNum" class="text-center text-3xl font-medium">
            暂无更多
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapState
} from "vuex"
import {
  getCategoryById
} from "@/api"
export default {
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.id = this.$route.params.id
        this.id == '0' ? this.query.categoryId = "" : this.query.categoryId = this.$route.params.id
        this.fetchProduct(this.page, this.query)
      }
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      current: 1,
      page: {
        current: 1,
        size: 12
      },
      productList: [],
      query: {
        categoryId: this.$route.params.id == "0" ? '' : this.$route.params.id,
        status: 1
      },
      pageNum: 0
    }
  },
  created() {
    this.fetchProduct(this.page, this.query)
  },
  computed: {
    ...mapState({
      categoryList: state => state.categoryList
    }),
    currentIndex() {
      let current = 0
      let cur = this.categoryList.findIndex((item, index) => {
        if (item.id == this.id) return index
      })
      if (cur >= 0) {
        return cur
      } else {
        return current
      }
    }
  },
  methods: {
    handleSearch() {
      this.fetchProduct(this.page, this.query)
    },
    handleViewDetail(id) {
      this.$router.push({
        path: `/product-detail/${id}`
      })
    },
    handleChangeCategory(id) {
      this.$router.push({
        path: `/product/${id}`
      })
    },
    async fetchProduct(page, params = {}) {
      const res = await getCategoryById(page.current, page.size, Object.assign(params, this.query))
      this.productList = res.data.records
      let num = Math.ceil(res.data.total / page.size)
      this.pageNum = num
      document.body.scrollTop = 110
      document.documentElement.scrollTop = 110
      // 分页
      if (this.productList.length != 0) {
        this.productList.forEach(item => {
          if (item.backgroundImage) {
            item.cover = item.backgroundImage.split(',')[0]
          }
        })
      }
    },
    handleChangePage(item) {
      if (this.current == item) return;
      if (item > this.pageNum) return;
      if (item == 0) return this.current = 1, this.page.curren = 1;
      this.current = item
      this.page.current = item
      this.fetchProduct(this.page, this.query)
    }
  },
}
</script>

<style lang="scss" scoped>
.bg-f4 {
  background: #f4f4f4;
}

.bg-item {
  background-image: url("../static/images/product_item.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.z-2 {
  z-index: 200;
}

.z-0 {
  z-index: 0;
}

.text-cut {
  overflow: hidden; //将超出的部分隐藏
  text-overflow: ellipsis; //超出部分用省略号显示
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
