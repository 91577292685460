<template>
<div class="bg-f4">
  <img src="@/static/images/news.png" alt="" class="mb-24 w-full">
  <div class="container mx-auto">
    <div class="lists pb-10 activityText">
      <div class="list pt-10 pb-6 px-8 bg-white mb-10 cursor-pointer animation-fade transform duration-700 transition-transform hover:scale-105 " :style="[{animationDelay: (index + 1) * 0.1 + 's'}]" v-for="(item,index) in newsList" :key="item.id" @click="handleViewDetail(item.id)">
        <h1 class="text-3xl font-medium truncate mb-3">{{item.title}}</h1>
        <div class="md:flex md:items-center mb-6">
          <!-- <span class="border-tags py-2 px-8 bg-orange mr-6 text-white text-lg md:text-2xl ">官方订阅</span> -->
          <span class="text-lg text-999 mr-10">{{item.createTime}}</span>
          <span class="flex items-center">
            <!-- <span class="text-lg text-999 mr-10 flex items-center mt-4 md:mt-0">
              <a-icon type="eye"></a-icon><span class="ml-2">199</span>
            </span> -->
            <span class="text-999 text-lg mt-4 md:mt-0 block">发布人:<span class="text-black">{{item.createUserName}}</span></span>
          </span>
        </div>
        <img v-if="item == 10" src="@/static/images/news.png" class="mb-8" alt="">
        <p class="text-xl text-333 font-medium mb-6">
          {{item.remark}}
        </p>
        <div class="text-right text-xl font-medium text-orange flex items-center justify-end">
          <span>阅读更多</span>
          <a-icon type="right"></a-icon>
        </div>
      </div>
    </div>
    <div class="flex w-full items-center justify-center pb-24 flex-wrap text-2xl md:text-lg" v-if="pageNum">
      <div class="pre cursor-pointer md:mr-10 mb-0" v-if="pageNum!=1" @click="handleChangePage(1)">首页</div>
      <div>
        <ul class="flex items-center flex-wrap">
          <li class="px-4 py-3 cursor-pointer " v-if="pageNum!=1" @click="handleChangePage(current - 1)">
            <a-icon type="left" />
          </li>
          <li v-for="(item,index) in pageNum" class="px-4 py-3 cursor-pointer" @click="handleChangePage(item)" :class="item == current ?'bg-orange text-white':''" :key="item">{{item}}</li>
          <li class="px-4 py-3 cursor-pointer" v-if="pageNum!=1" @click="handleChangePage(current + 1)">
            <a-icon type="right" />
          </li>
        </ul>
      </div>
      <div v-if="pageNum!=1" class="next cursor-pointer mt-0 ml-0 md:ml-10" @click="handleChangePage(pageNum)">尾页</div>
    </div>
  </div>
</div>
</template>

<script>
import {
  getArticle
} from '@/api'
export default {
  data() {
    return {
      current: 1,
      page: {
        current: 1,
        size: 10
      },
      query: {},
      pageNum: 0,
      newsList: []
    }
  },
  created() {
    this.fetchPage(this.page, this.query)
  },
  methods: {
    handleChangePage(item) {
      if (this.current == item) return;
      if (item > this.pageNum) return;
      if (item == 0) return this.current = 1, this.page.curren = 1;
      this.current = item
      this.page.current = item
      this.fetchPage(this.page, this.query)
    },
    async fetchPage(page, params = {}) {
      getArticle(page.current, page.size, Object.assign(params, this.query)).then(res => {
        this.newsList = res.data.records
        let num = Math.ceil(res.data.total / page.size)
        this.pageNum = num
      })
    },
    handleViewDetail(id) {
      this.$router.push({
        path: `/news-detail/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-tags {
  border-radius: 30px 0px 30px 30px;
}
</style>
